import { geneValues } from "../../patient/anamnesis/component/section/MutationModalForm";

// I suppose label is not part of widget config but part of the field itself
// because it's used not only in the widget

export const fields = {
    bio_sex: {
        widgetType: "dropdown",
        group: "general",
        widgetConfig: {
            label: "Bio sex",
            operators: ["equal"],
            values: ["MAN", "WOMAN"],
        },
    },
    "anamnesis.CARDIO_DISEASE.hasHealthyDiet": {
        widgetType: "switch",
        group: "Cardiovascular Risk",
        widgetConfig: {
            label: "Healthy Diet",
        },
    },
    "anamnesis.CARDIO_DISEASE.hasTimePressureAndStress": {
        widgetType: "switch",
        group: "Cardiovascular Risk",
        widgetConfig: {
            label: "Stress",
        },
    },
    "anamnesis.CCS": {
        widgetType: "switchWithSelect",
        group: "risk-score",
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CCS.hasCCS",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CCS.ccsValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "CSS Class",
            values: ["CCS2", "CCS3", "CCS4"],
        },
    },

    "anamnesis.CCS.hasCCS": {
        widgetType: "switch",
        widgetConfig: {},
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values_count": {
        widgetType: "simpleNumber",
        group: "chip",
        widgetConfig: {
            width: 48,
            label: "Chip Mutation Count",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    age: {
        widgetType: "dateRangeFilter",
        group: "general",
        widgetConfig: {
            width: 56,
            label: "Age",
        },
    },
    "anamnesis.CARDIOVASCULAR.smokeStatus": {
        widgetType: "switch",
        group: "other",
        widgetConfig: {
            label: "Smoke Status",
        },
    },

    "anamnesis.CARDIOVASCULAR.alcoholDrinkingStatus": {
        widgetType: "switch",
        group: "Cardiovascular Risk",
        widgetConfig: {
            label: "Drinking Status",
        },
    },

    "anamnesis.NYHA": {
        widgetType: "switchWithSelect",
        group: "risk-score",
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.NYHA.hasNYHA",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.NYHA.nyhaValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "NYHA Class",
            values: ["NYHA2", "NYHA3", "NYHA4"],
        },
    },
    "anamnesis.QUALITY_OF_LIFE.dailyActivityValue": {
        widgetType: "dropdown",
        group: "other",
        widgetConfig: {
            label: "Daily Activity Value",
            values: ["NO_PROBLEM", "SLIGHT_PROBLEM", "MODERATE_PROBLEM", "MAJOR_PROBLEM", "NOT_POSSIBLE"],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values": {
        widgetType: "nested",
        group: "chip",
        defaultRuleProperties: {
            value: {
                type: "nested",
                nestedValues: [
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
                            operator: "eq",
                            value: "",
                        },
                    },
                ],
            },
        },
        widgetConfig: {
            label: "Chip Mutation Data",
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.gene": {
        widgetType: "dropdown",
        widgetConfig: {
            width: 150,
            label: "Gene",
            values: geneValues,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.vaf": {
        widgetType: "simpleNumber",
        widgetConfig: {
            width: 80,
            label: "VAF",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.exon": {
        widgetType: "simpleNumber",
        widgetConfig: {
            label: "exon",
            width: 50,
            operators: [],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.dna": {
        widgetType: "autocomplete",
        widgetConfig: {
            label: "DNA",
            width: 150,
            fromPropValues: true,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.protein": {
        widgetType: "autocomplete",
        widgetConfig: {
            label: "Protein",
            width: 150,
            fromPropValues: true,
        },
    },
};
