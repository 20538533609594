import { useContext } from "react";
import { Modal, Box } from "@mui/material";
import { fields } from "../filter-configs/fields.conf";
import { defaultRulePropertiesByWidgetType } from "../filter-configs/defaultRulePropertiesByWidgetType";
import { queryFormContext } from "../filter-state/context";
import { ADD_RULE } from "../filter-state/actions";


const groupsWithFields = Object.entries(fields)
    .filter(([_, value]) => value.group)
    .reduce((acc, [field, value]) => {
        return acc[value.group]
            ? { ...acc, [value.group]: [...acc[value.group], { name: field, label: value.widgetConfig.label }] }
            : { ...acc, [value.group]: [{ name: field, label: value.widgetConfig.label }] };
    }, {});

export const AddFilterModal = ({isOpen, onClose}) => {
    const { formTree, dispatch } = useContext(queryFormContext);
    return <Modal open={isOpen} onClose={onClose}>
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: "8px",
                p: 4,
            }}
        >
            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" style={{ gap: "30px" }}>
                {Object.entries(groupsWithFields).map(([group, groupFields]) => (
                    <Box key={group} display="flex" flexDirection="column">
                        <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>{group}</span>
                        {groupFields.map((field) => {
                            const fieldAlreadyExists = formTree.some((rule) => rule.properties.field === field.name);
                            const rulePropertiesFromFieldsConfig = fields[field.name].defaultRuleProperties;
                            const rulePropertiesFromWidgetType =
                                defaultRulePropertiesByWidgetType[fields[field.name].widgetType];
                            const defaultProperties = rulePropertiesFromFieldsConfig
                                ? rulePropertiesFromFieldsConfig
                                : rulePropertiesFromWidgetType
                                  ? rulePropertiesFromWidgetType
                                  : {};
                            return (
                                <div
                                    style={{
                                        color: fieldAlreadyExists ? "gray" : "black",
                                        paddingLeft: "2px",
                                    }}
                                    key={field.name}
                                    onClick={() => {
                                        if (fieldAlreadyExists) return;
                                        dispatch({
                                            type: ADD_RULE,
                                            payload: {
                                                type: "rule",
                                                properties: {
                                                    field: field.name,
                                                    value: "",
                                                    disabled: false,
                                                    ...defaultProperties,
                                                },
                                            },
                                        });
                                        onClose();
                                    }}
                                >
                                    - {field.label}
                                </div>
                            );
                        })}
                    </Box>
                ))}
            </Box>
        </Box>
    </Modal>
 };
