import { FormControl, MenuItem, Select, InputLabel, Box } from "@material-ui/core";

const generateMenuItems = (values) => {
    return ["", ...(values).filter(v => v !== "")].map((value, dropdownKey) => (
        <MenuItem key={dropdownKey} value={value}>
            {value === "" ? "Select value" : value}
        </MenuItem>
    ));
};

export const DropdownRenderer = ({ config, rule, idx, handleUpdateRule, dynamicSelectValues = {}}) => {
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                {config.compact ? null : <div>{config.label || rule.properties.field}</div>}
            </Box>
            <Box display="flex" flexDirection="column">
                <FormControl size="small">
                    {config.compact && <InputLabel shrink>{config.label || rule.properties.field}</InputLabel>}
                    <Select
                        value={rule.properties.value}
                        displayEmpty
                        onChange={(e) => {
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value: e.target.value,
                            });
                        }}
                    >
                        {config.fromPropValues
                            ? generateMenuItems(dynamicSelectValues[rule.properties.field])
                            : generateMenuItems(config.values)}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};
