import Flex from "components/grid/Flex";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useAddCohortMutation, useGetPatientsFromElasticQuery } from "./CohortApi";
import CohortPatientRow from "./CohortPatientRow";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";
import React, { useEffect, useState } from "react";
import { PATIENT_PAGE } from "routes/routes";
import HeaderItem from "components/header-item/HeaderItem";
import _ from "lodash";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import { useEnqueueError, useEnqueueSuccess } from "components/alert/SnackbarHooks";

const PatientHeader = (props) => {
    const { translate, onSort, sortParameter, reverseSort } = props;

    return (
        <Flex
            item
            container
            grow={0}
            style={{
                backgroundColor: "#F8F8FA",
                height: 40,
                marginTop: 10,
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid #E2E1E4",
            }}
        >
            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.name"),
                    sortName: "familyName",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                    {...{
                        left: true,
                        title: translate("global.clinics"),
                        sortName: "clinics",
                        onSort,
                        reverseSort,
                        sortParameter,
                    }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.programs"),
                    sortName: "programs",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("global.age"),
                    sortName: "age",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    title: translate("global.manage"),
                    sortName: "",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />
        </Flex>
    );
};

const Row = ({ data, index, style }) => {
    const { selectPatient, checkedPatientIds, checkPatient, rows } = data;
    return (
        <CohortPatientRow
            key={index}
            {...{
                style,
                patient: rows[index],
                selectPatient,
                checkedPatientIds,
                checkPatient,
            }}
        />
    );
};

function PatientListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const { patients, selectPatient, checkedPatientIds, checkPatient } = props;

    return (
        <div
            ref={ref}
            style={{
                height: '40vh'
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={patients.length}
                itemSize={40}
                width={width || 500}
                itemData={{
                    rows: patients,
                    selectPatient,
                    checkedPatientIds,
                    checkPatient,
                }}
            >
                {Row}
            </FixedSizeList>
        </div>
    );
}

const CohortPatientList = ({ query }) => {
    const { data, error, isLoading } = useGetPatientsFromElasticQuery(query, { cacheTime: 30 });
    const { t: translate } = useTranslation();
    const [checkedPatientIds, setCheckedPatientIds] = useState([]);
    useEffect(()=>{
        if(!_.isEmpty(data) && !isLoading){
            setCheckedPatientIds(data.map(patient=>patient.patientId));
        }
    },[data, isLoading])
    const selectPatient = (patientId) => (event) => {
        window.open(PATIENT_PAGE.pathWithId(patientId), "_blank");
    };
    const checkPatient = (id) => {
        if (checkedPatientIds.includes(id)) {
            setCheckedPatientIds(checkedPatientIds.filter((item) => item !== id));
        } else {
            setCheckedPatientIds([...checkedPatientIds, id]);
        }
    };
    const [sortParameter, setSortParameter] = useState("");
    const [reverseSort, setReverseSort] = useState(false);
    const onSort = (newSortParameter) => (event) => {
        setReverseSort(newSortParameter === sortParameter ? !reverseSort : false);
        setSortParameter(newSortParameter);
    };
    const [cohortName, setCohortName] = useState('');
    const [ addCohort ] = useAddCohortMutation();
    const [saveCohortDialogOpen, setSaveCohortDialogOpen] = useState(false);
    const openSaveCohortDialog = () => {
        setSaveCohortDialogOpen(true);
        setCohortName('');
    }
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const saveCohort = ()=> {
        addCohort({
            name: cohortName,
            originalEsQuery: JSON.stringify(query),
            patientIds: checkedPatientIds
        }).unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch((error) => {
                    enqueueError();
                })
                .finally(() => {
                    setSaveCohortDialogOpen(false);
                });
    }
    return (
        <Flex item container column>
            <Flex item container justifyContent={'flex-end'} style={{marginTop: 10}}>
                <Button variant={'contained'} onClick={openSaveCohortDialog} disabled={_.isEmpty(checkedPatientIds)}
                color={'primary'}>
                    {translate('cohort.save-cohort')}
                </Button>
            </Flex>
            <PatientHeader
                {...{
                    onSort,
                    sortParameter,
                    reverseSort,
                    translate
                }}
            />
            <PatientListWindow {...{ patients: data || [], selectPatient, checkedPatientIds, checkPatient }} />
            <Flex
                    item
                    container
                    grow={0}
                    style={{
                        backgroundColor: "#F8F8FA",
                        height: 10,
                        borderRadius: "0px 0px 15px 15px",
                        border: "1px solid #E2E1E4",
                    }}
            />
            <Dialog open={saveCohortDialogOpen} maxWidth={'lg'}>
                <DialogTitle style={{paddingBottom: '2px'}}>
                    {translate("cohort.save-cohort-dialog-title")}
                </DialogTitle>
                <Flex item container column style={{minWidth: 700}} padding={10}>
                    <DefaultTextField {...{
                        placeholder: translate('cohort.save-cohort-dialog-placeholder'),
                        fullWidth: true,
                        onChange: event=> setCohortName(event.target.value),
                        value: cohortName,
                        label: translate('global.description')
                    }}/>
                    <Flex item container justifyContent={'flex-end'} style={{marginTop: 10}}>
                        <Button onClick={() => setSaveCohortDialogOpen(false)}
                                color="primary"
                                size={'small'}
                                variant="outlined">
                            <Trans i18nKey={"global.cancel"}/>
                        </Button>
                        <Button onClick={saveCohort}
                                style={{marginLeft: 10}}
                                variant="contained"
                                disabled={!cohortName}
                                autoFocus
                                size={'small'}
                                color="primary"
                        >
                            <Trans i18nKey={"cohort.save-cohort"}/>
                        </Button>
                    </Flex>
                </Flex>
            </Dialog>
        </Flex>
    );
};

export default CohortPatientList;
