import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const charOperatorToSymbol = {
    "eq": "=",
    "gt": ">",
    "lt": "<",
    "gte": "≥",
    "lte": "≤",
}

export const SimpleNumberRenderer = ({
  config,
  rule,
  idx,
  handleUpdateRule,
}) => {
  return (
    <div
      key={idx}
      style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {config.compact ? null : (
          <>
            <div>{config.label || rule.properties.field}</div>
          </>
        )}
      </div>
      <div style={{gap: '10px', display: 'flex'}}>

                { config.operators?.length > 0 && <FormControl size="small" >
                    {config.compact && <InputLabel shrink>{config.label || rule.properties.field}</InputLabel>  }
                    <Select
                        value={rule.properties.operator}
                        displayEmpty
                        onChange={(e) => {
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                operator: e.target.value,
                            });
                        }}
                    >
                        {[""].concat(config.operators).map((operator, operatorKey) => (
                            <MenuItem key={operatorKey} value={operator}>
                                {operator === "" ? "Select" : charOperatorToSymbol[operator] ?? operator}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl> }
        <TextField
        size="small"
          style={{width: config.width ? config.width + 'px' : undefined}}
          InputLabelProps={{ shrink: true }}
          type="number"
          label={config.compact ? (config.operators?.length > 0 ? ' ' : config.label || rule.properties.field) : null}
          value={rule.properties.value}
          onChange={(e) =>
            handleUpdateRule(idx, {
              ...rule.properties,
              value: e.target.value === "" ? "" : Number(e.target.value),
            })
          }
        />

      </div>
    </div>
  );
};
