import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, MenuItem, Select, InputLabel, Box, TextField } from "@material-ui/core";

export const AutocompleteRenderer = ({
    config,
    rule,
    idx,
    handleUpdateRule,
    dynamicSelectValues = {},
    abc,
    ...rest
}) => {
    const values = (config.fromPropValues ? dynamicSelectValues[rule.properties.field] : config.values).filter(v => v !== "");
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                {config.compact ? null : <div>{config.label || rule.properties.field}</div>}
            </Box>
            <Box display="flex" flexDirection="column">
                <FormControl size="small">
                    {/* {config.compact && <InputLabel shrink>{config.label || rule.properties.field}</InputLabel>} */}

                    <Autocomplete
                        freeSolo
                        options={values}
                        value={rule.properties.value}
                        size="small"
                        onInputChange={(e, value) => {
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value,
                            });
                        }}
                        filterOptions={(options) =>
                            options.filter((option) =>
                                option.toLowerCase().includes(rule.properties.value.toLowerCase())
                            )
                        }
                        disableClearable
                        renderInput={(params) => {
                            return (
                                <TextField
                                size="small"
                                    {...params}
                                    placeholder=""
                                    onChange={params.onChange}
                                    value={params.value}
                                    InputLabelProps={{ shrink: true, }}
                                    label={config.label || rule.properties.field}
                                    style={{width: config.width ? config.width + 'px' : undefined}}
                                />
                            );
                        }}
                    />
                </FormControl>
            </Box>
        </Box>
    );
};
