import React, { useState } from "react";
import { Dialog, Typography } from "@material-ui/core";
import Flex from "components/grid/Flex";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import HmoFilledButton from "components/button/HmoFilledButton";
import HmoBorderedButton from "components/button/HmoBorderedButton";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import { DOCTOR, ASSISTANT } from "domain/User.model";
import { enqueueSnackbar } from "notistack";
import { useCreateCareProgramInstanceMutation } from "scenes/patient/ProgramApi";
import { useGetCareProvidersQuery } from "scenes/patient/CareProviderApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isManager } from "domain/User.model";
import { useFetchProgramTemplatesQuery } from "scenes/patient/ProgramApi";
import { CARE_PROVIDER } from "domain/EntityAuthorizationType.model";

const NewProgramDialog = ({ cancel, patientId, patientList, refetch }) => {
    const user = useSelector((state) => state.mainReducer.user);
    const { t: translate } = useTranslation();
    const { data: programTemplates = [] } = useFetchProgramTemplatesQuery(CARE_PROVIDER, {
        skip: !isManager(user),
    });
    const [createCareProgramInstance] = useCreateCareProgramInstanceMutation();
    const { data: careProviders } = useGetCareProvidersQuery();
    const [selectedProgramTemplate, setSelectedProgramTemplate] = useState("");
    const [careProvider, setCareProvider] = useState("");
    const selectProgramTemplate = (event, selectedProgramTemplate) => {
        setSelectedProgramTemplate(selectedProgramTemplate);
    };

    const selectCareProvider = (event, _careProvider) => {
        setCareProvider(_careProvider);
    };

    const filteredCareProviders =
        user?.roles.includes(DOCTOR) || user?.roles.includes(ASSISTANT)
            ? careProviders?.filter((cp) => user?.careProviderIds.includes(cp.id))
            : careProviders;

    const selectedCareProvider =
        user?.roles.includes(DOCTOR) || user?.roles.includes(ASSISTANT)
            ? (careProviders || []).find((cp) => cp.id === user?.careProviderId)
            : "";

    const filteredProgramTemplates = programTemplates?.filter(
        (pt) => pt.careProviderId === (careProvider || selectedCareProvider)?.id,
    );

    const addProgram = () => {
        cancel();
        if (patientList) {
            const patientIdsToAddTo = patientList
                .filter((patient) =>
                    patient.programsData
                        .filter(
                            (program) =>
                                program.suspendedParticipation === null || program.suspendedParticipation === false,
                        )
                        .every((program) => program.shortName !== selectedProgramTemplate?.shortName),
                )
                .map((patient) => patient.patientId);
            const promises = [];
            patientIdsToAddTo.forEach((id) =>
                promises.push(
                    createCareProgramInstance({
                        patientId: id,
                        programTemplateId: selectedProgramTemplate?.id,
                        careProviderId: selectedProgramTemplate.careProviderId,
                    }),
                ),
            );
            Promise.allSettled(promises).then(() => refetch());
        } else {
            createCareProgramInstance({
                patientId,
                programTemplateId: selectedProgramTemplate?.id,
                careProviderId: selectedProgramTemplate.careProviderId,
            })
                .unwrap()
                .then(() => {
                    enqueueSnackbar(
                        translate("program.program-assigned-to-patient-message", {
                            programName: selectedProgramTemplate.name,
                        }),
                        { variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" } },
                    );
                })
                .catch((error) => {
                    enqueueSnackbar(translate("global.error"), {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "bottom" },
                    });
                });
        }
    };

    return (
        <Dialog open={true}>
            <Flex item container column padding={15} style={{ width: "100vw", maxWidth: 400 }}>
                <Flex item container justifyContent={"space-between"} alignItems={"center"} style={{ marginRight: 7 }}>
                    <Typography variant={"h5"}>{translate("global.new-program")}</Typography>
                </Flex>

                {filteredCareProviders && !_.isEmpty(filteredCareProviders) && (
                    <Autocomplete
                        options={filteredCareProviders || []}
                        onChange={selectCareProvider}
                        disabled={filteredCareProviders.length < 2}
                        name={"careProvider"}
                        value={careProvider || selectedCareProvider || null}
                        style={{ width: "100%" }}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id;
                        }}
                        getOptionLabel={(cp) => cp.name}
                        renderOption={(cp) => cp.name}
                        renderInput={(params) => (
                            <DefaultTextField
                                {...params}
                                fullWidth
                                label={translate("global.care-provider")}
                                placeholder={translate("global.please-select")}
                            />
                        )}
                    />
                )}

                {programTemplates && !_.isEmpty(programTemplates) && (
                    <Autocomplete
                        data-testid="new-program-dialog-program-template-autocomplete"
                        options={
                            filteredProgramTemplates.filter((item) => item.name).filter((item) => !item.suspended) || []
                        }
                        onChange={selectProgramTemplate}
                        name={"programTemplate"}
                        disabled={!selectedCareProvider && !careProvider}
                        value={selectedProgramTemplate || null}
                        style={{ width: "100%" }}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id;
                        }}
                        getOptionLabel={(program) => program.name}
                        renderOption={(program) => program.name}
                        renderInput={(params) => (
                            <DefaultTextField
                                {...params}
                                fullWidth
                                label={translate("global.program")}
                                placeholder={translate("global.please-select")}
                            />
                        )}
                    />
                )}

                <Flex item container style={{ marginTop: 10, marginRight: 7 }}>
                    <HmoBorderedButton onClick={cancel} size={"small"} style={{ width: "50%", marginRight: 10 }}>
                        {translate("global.cancel")}
                    </HmoBorderedButton>
                    <HmoFilledButton
                        data-testid="new-program-dialog-add-program-submit-button"
                        onClick={addProgram}
                        disabled={!(careProvider || !!selectedCareProvider) || !selectedProgramTemplate}
                        style={{ width: "50%" }}
                    >
                        {translate("global.add-program")}
                    </HmoFilledButton>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default NewProgramDialog;
