import { Switch, Box } from "@material-ui/core";

export const SwitchRenderer = ({ config, rule, idx, handleUpdateRule }) => (
    <Box key={idx} display="flex" style={{ gap: "10px" }}>
        <Box display="flex" alignItems="center">
            <span>{config.label || rule.properties.field}</span>
        </Box>

        <Box display="flex" alignItems="center">
            <span>No</span>
            <Switch
                checked={rule.properties.value}
                size="small"
                onChange={(e) =>
                    handleUpdateRule(idx, {
                        ...rule.properties,
                        value: e.target.checked,
                    })
                }
                color="primary"
            />
            <span>Yes</span>
        </Box>
    </Box>
);
