import { makeStyles } from "@material-ui/core/styles";
import Flex from "components/grid/Flex";
import { Button, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState, useReducer, useEffect } from "react";
import HmoFilledButton from "components/button/HmoFilledButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DefaultTextField from "../../components/hmo-textfield/DefaultTextField";
import { FilterBuilder } from "./FilterBuilder";
import formStructureReducer from "./filter-state/reducer";
import formStructureToElasticQuery from "./formStructureToElasticQuery";
import { useAddFilterMutation, useGetCountFromElasticQuery } from "./CohortApi";
import { useSelector } from "react-redux";
import { queryFormContext } from "./filter-state/context";
import CohortPatientList from "./CohortPatientList";
import { QueryHitsCounter } from "./QueryHitsCounter";

const useStyles = makeStyles((theme) => ({}));

const CohortFilterNew = ({ route }) => {
    const [saveFilter] = useAddFilterMutation();
    const [formTree, dispatch] = useReducer(formStructureReducer, []);
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const [name, setName] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [selectedClinicId, setSelectedClinicId] = useState(0);
    const toggleIsFilterOpen = () => setIsFilterOpen(!isFilterOpen);
    const clinics = [];
    const elasticQuery = formStructureToElasticQuery(formTree);
    const { data, error, isLoading } = useGetCountFromElasticQuery(elasticQuery, { cacheTime: 30 });
    const currentUserId = useSelector((state) => state.mainReducer.user.id);
    const [showResultsForQuery, setShowResultsForQuery] = useState("");
    useEffect(() => {
        if(JSON.stringify(elasticQuery) !== JSON.stringify(showResultsForQuery)){
            setShowResultsForQuery("");
        }
    }, [elasticQuery]);
    const showResults = () => {
        setShowResultsForQuery(elasticQuery);
    };
    const save = () => {
        saveFilter({
            name,
            filterText: JSON.stringify(formTree),
            esQuery: JSON.stringify(elasticQuery),
            creatorUserId: currentUserId,
            creationDate: new Date(),
        });
    };
    return (
        <Flex item container column>
            <Flex item container center >
                <TextField
                    style={{paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                    value={name}
                    fullWidth
                    onChange={(event) => setName(event.target.value)}
                    placeholder={translate("cohort.name-placeholder")}
                />
                <HmoFilledButton style={{ width: 150 }} onClick={save} disabled={!name}>
                    {translate("cohort.save")}
                </HmoFilledButton>
            </Flex>
            <Flex
                item
                container
                style={{
                    border: "1px solid lightgrey",
                    backgroundColor: "#F6F8FA",
                }}
            >
                <Flex item grow={1} container column style={{ flex: "0 0 80%" }}>
                    <Flex item={"0 0 0"} container>
                        <IconButton onClick={toggleIsFilterOpen} style={{ padding: 3 }}>
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <Typography style={{ fontWeight: "bold" }}>
                            {translate("cohort.create-filter-according")}
                        </Typography>
                    </Flex>
                    <queryFormContext.Provider value={{ formTree, dispatch }}>
                        <FilterBuilder {...{ translate }} />
                    </queryFormContext.Provider>
                    <Button
                        style={{ margin: 15, width: 200, textTransform: "none" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!data}
                        onClick={showResults}
                    >
                        {translate("cohort.show-result")}
                    </Button>
                </Flex>
                <Flex item container column center>
                    <DefaultTextField
                        label=""
                        select
                        SelectProps={{
                            value: clinics.find((clinic) => clinic.id === selectedClinicId)?.id || 0,
                            onChange: (event, value) => setSelectedClinicId(event.target.value),
                        }}
                        style={{ width: 200 }}
                    >
                        <MenuItem key={"Empty"} value={0}>
                            {translate("cohort.all-clinics")}
                        </MenuItem>
                        {clinics.map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id}>
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </DefaultTextField>
                    <QueryHitsCounter {...{isLoading, error, count: data}}/>
                </Flex>
            </Flex>
            {
                showResultsForQuery &&
                    <CohortPatientList {...{ query: showResultsForQuery }} />
            }
        </Flex>
    );
};

export default CohortFilterNew;
