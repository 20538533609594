import { Switch, FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";

export const SwitchWithSelectRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <span>{config.label || rule.properties.field}</span>
            </Box>

            <Box display="flex" alignItems="center">
                <span>No</span>
                <Switch
                    checked={rule.properties.value.switchValue.value}
                    size="small"
                    onChange={(e) =>
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                switchValue: {
                                    field: rule.properties.value.switchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }
                    color="primary"
                />
                <span>Yes</span>
            </Box>

            <FormControl size="small">
                {config.compact && <InputLabel shrink>{rule.properties.field}</InputLabel>}
                <Select
                    disabled={!rule.properties.value.switchValue.value}
                    value={rule.properties.value.selectValue.value}
                    displayEmpty
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                selectValue: { field: rule.properties.value.selectValue.field, value: e.target.value },
                            },
                        });
                    }}
                >
                    {[""].concat(config.values).map((value, dropdownKey) => (
                        <MenuItem key={dropdownKey} value={value}>
                            {value === "" ? "Select value" : value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
