import { Box } from "@material-ui/core";
import { fields } from "../../filter-configs/fields.conf";
import { inputRendererMap } from "../../filter-configs/inputRendererMap";

export const NestedMatch = ({ config, rule, idx, handleUpdateRule, dynamicSelectValues }) => {
    return (
        <Box
        display="flex"
        alignItems="flex-end"
        >
            <span style={{ marginBottom: "4px" }}>{config.label || rule.properties.field}</span>

            {rule.properties.value.nestedValues.map((innerRule, innerRuleIdx) => {
                const field = fields[innerRule.properties.field];
                const RenderField = inputRendererMap[field.widgetType];
                return RenderField ? (
                    <RenderField
                        key={innerRuleIdx}
                        config={{ ...field.widgetConfig, compact: true }}
                        rule={innerRule}
                        idx={innerRuleIdx}
                        handleUpdateRule={(nestedIdx, updatedProperties) => {
                            const updatedRule = { type: "rule", properties: updatedProperties };
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value: {
                                    ...rule.properties.value,
                                    nestedValues: rule.properties.value.nestedValues.map((innerRule, innerIdx) => {
                                        return innerIdx === nestedIdx ? updatedRule : innerRule;
                                    }),
                                },
                            });
                        }}
                        dynamicSelectValues={dynamicSelectValues}
                    />
                ) : null;
            })}
        </Box>
    );
};
