// note: by default if value is not present here or in the field config, it will be an empty string

export const defaultRulePropertiesByWidgetType = {
    dateRangeFilter: {
      value : {
        type: "dateRange",
        from: 18,
        to: 40,
      },
    },
    switch: {
        value: true,
    },
    simpleNumber: {
        operator: "",
        value: "",
    }
};

