import { TextField, Box } from "@material-ui/core";
export const TermRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    return (
        <Box key={idx} display="flex" flexDirection="row" style={{ gap: "10px" }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                {config.compact ? null : <span>{config.label || rule.properties.field}</span>}
            </Box>
            <TextField
                style={{ width: config.width ? config.width + "px" : undefined }}
                size="small"
                InputLabelProps={{ shrink: true }}
                label={config.compact ? config.label || rule.properties.field : ""}
                value={rule.properties.value}
                onChange={(e) => {
                    handleUpdateRule(idx, {
                        ...rule.properties,
                        value: e.target.value,
                    });
                }}
            />
        </Box>
    );
};
