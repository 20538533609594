import { AutocompleteRenderer } from "../filter-components/searchInputRenderers/AutoCompleteRenderer";
import { SwitchRenderer, RangeRenderer, DropdownRenderer, SimpleNumberRenderer, SwitchWithSelectRenderer, NestedMatch, TermRenderer } from "../filter-components/searchInputRenderers/index";
export const inputRendererMap = {
  dropdown: DropdownRenderer,
  autocomplete: AutocompleteRenderer,
  simpleNumber: SimpleNumberRenderer,
  switch: SwitchRenderer,
  dateRangeFilter: RangeRenderer,
  switchWithSelect: SwitchWithSelectRenderer,
  nested: NestedMatch,
  term: TermRenderer,
};
